const initialState = {
  currentLanguage: 'en',
  originalPath: '/',
  loggedin: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        loggedin: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        loggedin: false,
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        currentLanguage: action.lang,
      };
    default:
      throw new Error('Erreur, manque une action.type');
  }
};

export {initialState, reducer};
