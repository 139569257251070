import { Translations } from "@aws-amplify/ui-components";

const fr = {
  [Translations.BACK_TO_SIGN_IN]: "Retour",
  [Translations.CHANGE_PASSWORD_ACTION]: "Changer",
  [Translations.CHANGE_PASSWORD]: "Changer le mot de passe",
  [Translations.CODE_LABEL]: "Code de vérification",
  [Translations.CODE_PLACEHOLDER]: "Code",
  [Translations.EMAIL_LABEL]: "Adresse email *",
  [Translations.EMAIL_PLACEHOLDER]: "Votre email",
  [Translations.FORGOT_PASSWORD_TEXT]: "Mot de passe oublié ?",
  [Translations.NEW_PASSWORD_LABEL]: "Nouveau mot de passe",
  [Translations.NEW_PASSWORD_PLACEHOLDER]: "Indiquez le nouveau mot de passe",
  [Translations.USERNAME_LABEL]: "Email *",
  [Translations.USERNAME_PLACEHOLDER]: "Indiquez votre email",
  [Translations.PASSWORD_LABEL]: "Mot de passe *",
  [Translations.PASSWORD_PLACEHOLDER]: "Indiquez votre mot de passe",
  [Translations.RESET_PASSWORD_TEXT]: "Réinitialiser le mot de passe",
  [Translations.RESET_YOUR_PASSWORD]: "Réinitialiser le mot de passe",
  [Translations.SEND_CODE]: "Envoyer le code",
  [Translations.SUBMIT]: "Envoyer",
  [Translations.SIGN_IN_ACTION]: "Connexion",
  [Translations.SIGN_IN_HEADER_TEXT]: "Se connecter",
  [Translations.SIGN_IN_TEXT]: "Se connecter",
  [Translations.SIGN_OUT]: "Se déconnecter",
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Vous avez un compte ?",
  [Translations.CREATE_ACCOUNT_TEXT]: "Créez un compte",
  [Translations.SIGN_UP_HEADER_TEXT]: "Créer un compte",
  [Translations.NO_ACCOUNT_TEXT]: "Pas de compte ?",
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Code de confirmation",
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Indiquez votre code",
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Confirmation",
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "Code perdu ?",
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Renvoyer un code",
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Confirmer",
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Créer mon compte",
  [Translations.NAME_LABEL]: "Nom",
  [Translations.NAME_PLACEHOLDER]: "Paul Becker",
  [Translations.DEFAULT_MSG]: "Erreur d'authentification",
  [Translations.EMPTY_USERNAME]: "L'email est obligatoire",
  [Translations.INVALID_USERNAME]: "L'email n'est pas valide",
  [Translations.EMPTY_PASSWORD]: "Le mot de passe est obligatoire",
  [Translations.EMPTY_CODE]: "Le code de confirmation est obligatoire",
  [Translations.SIGN_UP_ERROR]: "Erreur de création de compte",
};

export default fr;
