import { grommet } from "grommet";
import { deepMerge } from "grommet/utils";

export default deepMerge(grommet, {
  global: {
    font: {
      family: "Playfair Display",
      size: "16px",
      height: "20px",
    },
    colors: {
      black: "#000",
      white: "#fff",
      brand: "#00202F",
      focus: "brand",
      "status-critical": "#D1283D",
      "status-warning": "#DB9A2F",
      "status-ok": "#84B804",
      "accent-1": "#D1283D",
      "light-4": "#b4b4b4",
      "graph-0": "brand",
    },
    breakpoints: {
      tiny: { value: 767 },
      small: { value: 991 },
      medium: { value: 1199 },
      large: {},
    },
    focus: {
      border: { color: "transparent" },
    },
  },
  anchor: {
    textDecoration: "underline",
  },
  button: {
    padding: {
      vertical: "15px",
      horizontal: "25px",
    },
    border: {
      width: "2px",
      radius: "2px",
    },
    size: { small: { border: { radius: "2px" } } },

    primary: {
      extend: "color: #fff;",
      radius: "2px",
    },
  },
  checkBox: { extend: "font-size: 15px" },
  formField: { border: { color: "transparent" } },
  heading: {
    extend: "color:#00202F",
    level: {
      1: {
        font: {},
        small: {
          size: "26px",
          height: "32px",
        },
        medium: {
          size: "34px",
          height: "40px",
        },
        large: {
          size: "50px",
          height: "56px",
        },
        xlarge: {
          size: "66px",
          height: "72px",
        },
      },
      3: {
        font: {
          weight: 400,
        },
      },
    },
  },
  menu: {
    background: "transparent",
  },
  radioButton: {
    size: "20px",
    extend: "flex: 0 0 20px",
    icon: {
      size: "20px",
      extend: "flex: 0 0 20px",
    },
  },
  select: {
    icons: {
      color: "dark-2",
    },
  },
  textInput: {
    extend: "border:1px solid #000;border-radius:2px;",
  },
});
