import React, {useReducer, useMemo} from 'react';
import {initialState, reducer} from './AppReducer';

const AppContext = React.createContext(initialState);

const AppContextProvider = ({children}) => {
  const [appState, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    return {appState, dispatch};
  }, [appState, dispatch]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export {AppContextProvider, AppContext};
